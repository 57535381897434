var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height grey lighten-4",attrs:{"fluid":""}},[_c('v-navigation-drawer',{staticClass:"grey lighten-4",attrs:{"mini-variant-width":"56","permament":"","mini-variant":_vm.miniVariant,"clipped":_vm.clipped,"stateless":"","fixed":"","app":"","mobile-break-point":"md","width":"220"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticStyle:{"padding-top":"0px","padding-bottom":"0px"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"value":"true","to":item.path}},[_c('v-list-item-icon',{staticStyle:{"margin-right":"6px"}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-action'),_c('v-list-item-content',{staticStyle:{"margin-left":"-24px","font-family":"SF Pro Text"}},[_vm._v(_vm._s(item.text))])],1)}),1),_c('div',{staticClass:"d-flex justify-center grey lighten-4 align-center"})],1),_c('v-app-bar',{attrs:{"app":"","clipped-left":_vm.clipped,"color":"grey lighten-3","height":"64"}},[_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":function($event){$event.stopPropagation();_vm.miniVariant = !_vm.miniVariant}}},[_c('v-icon',[_vm._v(" mdi-menu-open ")])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"grey lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"ml-6 mr-5",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/logo.png"),"min-height":"16","min-width":"16","max-height":"48","max-width":"40"},on:{"click":function($event){return _vm.redirectToMain()}}},'v-img',attrs,false),on))]}}])},[_c('span',{staticClass:"SF_Pro",staticStyle:{"color":"#000000","font-size":"14px"}},[_vm._v("Вернуться")])]),_c('v-toolbar-title',{staticClass:"login_text pa-1",staticStyle:{"font-size":"24px","line-height":"18px"}},[_vm._v(" Панель администрирования ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":"","nudge-bottom":"10","close-on-content-click":false,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"color":"blue","size":"48"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.account_data.initials))])])],1)]}}])},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('h3',{staticStyle:{"padding-top":"12px","padding-bottom":"12px","font-family":"SF Pro Text"}},[_vm._v(" "+_vm._s(_vm.account_data.fullName)+" ")]),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{staticClass:"justify-start",staticStyle:{"font-family":"SF Pro Text"},attrs:{"depressed":"","rounded":"","text":"","width":"150"}},[_c('v-icon',{attrs:{"left":"","color":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" Аккаунт ")],1),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{staticClass:"justify-start",staticStyle:{"font-family":"SF Pro Text"},attrs:{"depressed":"","rounded":"","text":"","width":"150"},on:{"click":function($event){return _vm.logOut()}}},[_c('v-icon',{attrs:{"left":"","color":""}},[_vm._v(" mdi-logout-variant ")]),_vm._v(" Выйти ")],1)],1)])],1)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"grey lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"justify-center mx-2",staticStyle:{"font-family":"SF Pro Text"},attrs:{"depressed":"","icon":"","text":""},on:{"click":function($event){return _vm.logOut()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-logout-variant ")])],1)]}}])},[_c('span',{staticClass:"SF_Pro",staticStyle:{"color":"#000000","font-size":"14px"}},[_vm._v("Выйти")])])],1),_c('v-content',[_c('v-breadcrumbs',{attrs:{"items":_vm.BreadcrumbsItems()},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }