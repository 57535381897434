


























































































































































































import { Component, Vue } from "vue-property-decorator";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { Region } from "@/models/Region";
import { User } from "@/models/User";
import {
	REGION_REQUEST,
	REGION_UPDATED,
	REGION_LOADED,
} from "@/store/actions/main";
import { USER_REQUEST, USER_UPDATED, USER_LOADED } from "@/store/actions/user";
@Component({})
export default class Pages extends Vue {
	private regions: any = [];
	private users: any = [];
	private clipped: boolean = true;
	private drawer: boolean = true;
	private miniVariant: boolean = false;
	private right: boolean = true;
	private title: string = "";
	private regionName = "";
	private loading: boolean = true;
	private interval: any = {};
	private account_data: any = {
		initials: "ОШ",
		fullName: "Олег Шурман",
	};
	private regionId: number = 0;
	private items = [
		{ text: "Виджеты", icon: "mdi-widgets", path: "/pages/widgets" },
		{ text: "Шаблоны", icon: "mdi-chart-pie", path: "/pages/templates" },
		{ text: "Мозаики", icon: "mdi-table-large", path: "/pages/pages" },
		{ text: "Показатели", icon: "mdi-chart-bar", path: "/pages/indicators" },
		{
			text: "Таблицы показателей",
			icon: "mdi-table",
			path: "/pages/indicatorsTables",
		},
		{
			text: "Гос. программы",
			icon: "mdi-notebook",
			path: "/pages/statePrograms",
		},
		//{ text: "Таблицы данных", icon: "mdi-table", path: "/pages/dataTables" },
		//{ text: "Доп. таблицы", icon: "mdi-table", path: "/pages/secondary" },
		{ text: "Пользователи", icon: "mdi-account-group", path: "/pages/users" },
		{ text: "Регионы", icon: "mdi-map-marker-radius", path: "/pages/regions" },
	];
	//Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];
		needReload.push(
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		);
		needReload.push(
			this.needReload(
				this.$store.getters.USER_TABLE,
				this.$store.getters.USER_LOADED
			)
		);
		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("tabl", this.$store.getters.REGION_TABLE);
		console.log("loaded", this.$store.getters.REGION_LOADED);
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;
		if (
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		) {
			this.$store.dispatch(REGION_REQUEST).then(() => {
				this.initIfReady();
			});
		}
		if (
			this.needReload(
				this.$store.getters.USER_TABLE,
				this.$store.getters.USER_LOADED
			)
		) {
			this.$store.dispatch(USER_REQUEST).then(() => {
				this.initIfReady();
			});
		}
	}
	private initSelf() {
		console.log("INIT SELF");
		this.regions = this.$store.getters.REGION_TABLE.data;
		this.users = this.$store.getters.USER_TABLE.data;
		if (
			localStorage.getItem("role") != "SU" &&
			localStorage.getItem("role") != "Admin"
		) {
			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].text == "Шаблоны") {
					this.items.splice(i, 1);
				}
			}
		}
		console.log(this.BreadcrumbsItems());
		this.miniVariant = this.isSmall();
		let fn = "";
		if (localStorage.getItem("name"))
			fn = this.toString(localStorage.getItem("name"));
		let sn = "";
		this.account_data.fullName = this.toString(fn);
		let arr = fn.split(" ");
		if (arr.length > 1) {
			sn = arr[0][0] + arr[1][0];
		} else {
			sn = arr[0][0];
		}
		this.account_data.initials = sn;
		this.regionId = +this.toString(localStorage.getItem("region_id"));
		for (let i = 0; i < this.regions.length; i++) {
			if (this.regions[i].id == this.regionId) {
				this.regionName = this.regions[i].name;
			}
		}
		this.loading = false;
		console.log("loading");
	}
	//Methods
	private toString(str) {
		return str as string;
	}
	private routerTo(path) {
		this.$router.push({ name: path });
	}
	private redirectToMain() {
		this.$router.push("/pages/widgets"); // ---> Redirect to default page, but default page is not yet defined
	}
	private logOut() {
		this.$store.dispatch(AUTH_LOGOUT, {}).then(() => {
			this.$router.push("/");
			location.reload();
		});
	}
	private mounted() {
		this.tryToInitSelf();
	}
	private async created() {
		this.interval = setInterval(() => {
			var exp = +this.toString(localStorage.getItem("exp"));
			var time = +Date.now() / 1000;
			if (time >= exp) {
				this.$store.dispatch(AUTH_LOGOUT, {}).then(() => {
					this.$router.push("/");
					location.reload();
				});
			} else {
				//console.log("seconds left", exp - time);
			}
		}, 1000);
	}
	private destroyed() {
		clearInterval(this.interval);
	}
	private BreadcrumbsItems() {
		return this.$route.meta.breadcrumb;
	}
	private isSmall() {
		switch (this.$vuetify.breakpoint.name) {
			case "xs":
				return true;
			case "sm":
				return true;
			case "md":
				return false;
			case "lg":
				return false;
			case "xl":
				return false;
			default:
				return false;
		}
	}
}
